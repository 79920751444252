export default {
  caseClause: "( Cause: Bad request.)",
  badRequest: "( Cause: Bad request )",
  closed: "( Cause: Connection closed. )",
  nxdomain: "( Cause: Non-existant domain. )",
  ehostunreach: "( Cause: No route to host. )",
  enetunreach: "( Cause: Network unreachable. )",
  reqTimedout: "( Cause: Request to the camera timed out. )",
  timeout: "( Cause: Camera response timed out. )",
  connectTimeout: "( Cause: Connection to the camera timed out. )",
  econnrefused: "( Cause: Connection refused. )",
  notFound: "( Cause: Camera url is not found. )",
  forbidden: "( Cause: Camera responded with a Forbidden message. )",
  unauthorized: "( Cause: Please check the username and password. )",
  deviceError: "( Cause: Camera responded with a Device Error message. )",
  deviceBusy: "( Cause: Camera responded with a Device Busy message. )",
  moved: "( Cause: Camera url has changed, please update it. )",
  notAJpeg: "( Cause: Camera didn't respond with an image. )",
  unhandled: "( Cause: Sorry, we dropped the ball. )",
}
